<template>
  <div class="keyboard-wrap" v-if="kbShow">
    <div class="head">
      <div class="done" @click="done">
        完成 <i class="el-icon-arrow-down"></i>
      </div>
    </div>

    <div class="key-list">
      <div
        class="colspan"
        :class="{ active: index === currentIndex }"
        v-for="(item, index) in keyboardList"
        :key="index"
      >
        <div
          class="uni-flex"
          v-for="(secondItem, secondIndex) in item"
          :key="secondIndex"
        >
          <div
            class="item"
            :class="{ disable: thridItem.split(',')[1] == 'f' }"
            v-for="(thridItem, thridIndex) in secondItem"
            :key="thridIndex"
            @click="touchstartActive(thridItem)"
          >
            <div class="content">
              {{ thridItem.split(",")[0] }}
            </div>
          </div>
        </div>
      </div>
      <!-- 删除键 -->
      <div class="item del-item" @click="del">
        <div class="content">
          <i class="el-icon-delete"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PlateInput",
  data() {
    return {
      keyboardList: [], //键盘类型
      currentIndex: 0, //键盘区当前下标
      keyboardEdit: false,
    };
  },
  props: ["kbIndex", "kbType", "kbShow"],
  mounted() {
    this.keyboardList = [
      [
        ["京", "粤", "津", "晋", "冀", "蒙", "辽", "吉", "黑", "渝"],
        ["苏", "浙", "皖", "闽", "赣", "鲁", "鄂", "沪", "宁"],
        ["桂", "琼", "川", "贵", "云", "藏", "陕", "新"],
        ["豫", "湘", "青", "甘"],
      ],
      [
        ["1,f", "2,f", "3,f", "4,f", "5,f", "6,f", "7,f", "8,f", "9,f", "0,f"],
        ["Q", "W", "E", "R", "T", "Y", "U", "I,f", "O,f", "P"],
        ["A", "S", "D", "F", "G", "H", "J", "K", "L", ""],
        ["Z", "X", "C", "V", "B", "N", "M", "", "", ""],
      ],
      [
        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
        ["Q", "W", "E", "R", "T", "Y", "U", "I,f", "O,f", "P"],
        ["A", "S", "D", "F", "G", "H", "J", "K", "L", ""],
        ["Z", "X", "C", "V", "B", "N", "M", "", "", ""],
      ],
      [
        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
        ["Q", "W", "E", "R", "T", "Y", "U", "I,f", "O,f", "P"],
        ["A", "S", "D", "F", "G", "H", "J", "K", "L", ""],
        ["Z", "X", "C", "V", "B", "N", "M", "", "", ""],
      ],
      [
        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
        ["Q", "W", "E", "R", "T", "Y", "U", "I,f", "O,f", "P"],
        ["A", "S", "D", "F", "G", "H", "J", "K", "L", ""],
        ["Z", "X", "C", "V", "B", "N", "M", "", "", ""],
      ],
      [
        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
        ["Q", "W", "E", "R", "T", "Y", "U", "I,f", "O,f", "P"],
        ["A", "S", "D", "F", "G", "H", "J", "K", "L", ""],
        ["Z", "X", "C", "V", "B", "N", "M", "", "", ""],
      ],
      [
        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
        ["Q", "W", "E", "R", "T", "Y", "U", "I,f", "O,f", "P"],
        ["A", "S", "D", "F", "G", "H", "J", "K", "L", "警"],
        ["Z", "X", "C", "V", "B", "N", "M", "学", "", ""],
      ],
      [
        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
        ["Q", "W", "E", "R", "T", "Y", "U", "I,f", "O,f", "P"],
        ["A", "S", "D", "F", "G", "H", "J", "K", "L", ""],
        ["Z", "X", "C", "V", "B", "N", "M", "", "", ""],
      ],
    ];
  },
  watch: {
    //获取键盘区域下标
    kbIndex: {
      immediate: true,
      handler(res) {
        //转为数字类型，防止数据类型不能匹配
        this.currentIndex = Number(res);
      },
    },
    kbShow: {
      handler(val) {
        this.keyboardEdit = val;
      },
      immediate: true,
    },
  },
  methods: {
    done() {
      this.keyboardEdit = false;
      this.$emit("childClose", "");
    },
    //删除
    del() {
      this.$emit("childDel", this.currentIndex);
      if (this.currentIndex == 0) {
        return;
      }
      this.currentIndex--;
      this.$emit("childIndex", this.currentIndex);
    },
    //点击事件
    touchstartActive(res) {
      //键盘包含f字符不执行
      if (res.split(",")[1] == "f" || res == "") {
        return;
      }
      this.$emit("childValue", res);
      if (this.currentIndex == this.keyboardList.length - 1) {
        return;
      }
      this.currentIndex++;
      this.$emit("childIndex", this.currentIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.keyboard-wrap {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  .head {
    overflow: hidden;
    background-color: #ffffff;
    border-top: 1px #e6e5ef solid;
    .done {
      float: right;
      width: 80px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #007aff;
      font-size: 18px;
      .iconfont {
        margin-left: 6px;
        font-size: 30px;
      }
    }
  }

  .key-list {
    padding: 4px 0;
    background: #e3e9ec;
  }
  .uni-flex {
    display: flex;
    justify-content: center;
  }
  /*iphone键盘*/
  .item {
    width: 10%;
    height: 40px;
    margin: 6px 0;
    text-align: center;
    box-sizing: content-box;
    .content {
      line-height: 40px;
      background: #fff;
      font-size: 16px;
      border-radius: 5px;
      margin: 0 3px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    }
    &.disable .content {
      background-color: #d6dde0;
      color: #acb5b9;
    }
    &.item:not(.disable):active .content {
      background-color: #007aff;
      color: #fff;
    }
  }
  .colspan {
    display: none;
    &.active {
      display: block;
    }
  }
  .del-item {
    position: absolute;
    box-sizing: border-box;
    right: 4px;
    bottom: 4px;
    width: 20%;
    height: 40px;
    .content {
      height: 100%;
      .iconfont {
        line-height: 40px;
        font-size: 22px;
      }
    }
  }
}
</style>
